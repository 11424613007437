@media all and (max-width: 479px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.2rem; } }

@media (min-width: 480px) and (max-width: 767px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.68rem; } }

@media (min-width: 768px) and (max-width: 1199px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.92rem; } }

@media (min-width: 1200px) and (max-width: 1919px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.4rem; } }

@media (min-width: 1920px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.4rem; } }

div:not(.modulor_web_module__is_edit_mode) .analytics_module, div:not(.modulor_web_module__is_edit_mode)
.column_module, div:not(.modulor_web_module__is_edit_mode)
.yield_module {
  padding: 0; }

div:not(.modulor_web_module__is_edit_mode) .flash_module {
  padding-top: 0;
  padding-bottom: 0; }
