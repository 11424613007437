@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.index_page {
  &.modulor_page__is_current {
    >.modulor_web_modules {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      overflow-x: hidden;
    }

    .modulor_web_module {
      flex-basis: 100%;
    }

    .apply_link_module__content,
    .about_menu_module__content[data-width="1"],
    .featured_diary_entry_module__content,
    .featured_exhibition_module__content,
    .featured_info_module__content,
    .featured_project_module__content,
    .featured_publication_module__content,
    .featured_post_module__content {
      &.modulor_web_module {
        flex-basis: fluid-col(1, 2);
        width: fluid-col(1, 2);

        @include breakpoints(s xs) {
          flex-basis: fluid-col(1, 1);
          width: fluid-col(1, 1);
        }
      }
    }

    .group_portrait_module__content,
    .featured_course_list_module__content,
    .apply_link_module__content,
    .about_menu_module__content,
    .featured_diary_entry_module__content,
    .featured_exhibition_module__content,
    .featured_info_module__content,
    .featured_project_module__content,
    .featured_publication_module__content,
    .featured_post_module__content,
    .featured_everyday_life_module__content,
    .featured_video_module__content {
      margin-bottom: 0;
    }

    .group_portrait_module__section,
    .featured_course_list_module__section,
    .apply_link_module__section,
    .about_menu_module__section,
    .featured_diary_entry_module__section,
    .featured_exhibition_module__section,
    .featured_info_module__section,
    .featured_project_module__section,
    .featured_publication_module__section,
    .featured_post_module__section,
    .featured_everyday_life_module__section,
    .featured_video_module__section {
      position: relative;
    }

    .featured_course_list_module__section,
    .apply_link_module__section,
    .about_menu_module__section,
    .featured_diary_entry_module__section,
    .featured_exhibition_module__section,
    .featured_info_module__section,
    .featured_project_module__section,
    .featured_publication_module__section,
    .featured_post_module__section,
    .featured_video_module__section {
      &:before {
        @include svg-line-bg(vertical, color(red));

        content: "";
        display: block;

        position: absolute;
        top: 0;
        height: 100%;

        @include breakpoints {
          left: calc(#{-$web-module-padding} - .3rem);
          width: $web-module-padding;
        }
      }
    }

    .group_portrait_module__section,
    .featured_course_list_module__section,
    .apply_link_module__section,
    .about_menu_module__section,
    .featured_diary_entry_module__section,
    .featured_exhibition_module__section,
    .featured_info_module__section,
    .featured_project_module__section,
    .featured_publication_module__section,
    .featured_post_module__section,
    .featured_everyday_life_module__section,
    .featured_video_module__section {
      &:after {
        @include svg-line-bg(horizontal, color(red));

        content: "";
        display: block;

        position: absolute;
        left: 0;
        width: 100%;

        @include breakpoints {
          top: - $web-module-padding;
          height: $web-module-padding;
        }
      }
    }
  }

  @include website_is_in_fall_term {

    .group_portrait_module__content,
    .featured_course_list_module__content,
    .apply_link_module__content,
    .about_menu_module__content,
    .featured_project_module__content,
    .featured_publication_module__content,
    .featured_post_module__content,
    .featured_diary_entry_module__content,
    .featured_exhibition_module__content,
    .featured_info_module__content,
    .featured_everyday_life_module__content,
    .featured_video_module__content {
      text-align: right;
    }

    .featured_publication_module__content {
      .publication_page__thumbnail__wrapper {
        margin-right: 0;
        margin-left: auto;
      }
    }

    .featured_project_module__content {
      .project_page__thumbnail__attachment_image {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
