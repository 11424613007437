@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.publication_page__thumbnail__cover__content {
  position: relative;
  border: .1rem solid modulor-color(gray);

  &:before {
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 7.5%;
    z-index: 1;

    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(35, 35, 35, 0.336) 3%,
      rgba(81, 81, 81, 0.3152) 9%,
      rgba(122, 122, 122, 0.2924) 16%,
      rgba(158, 158, 158, 0.2682) 23%,
      rgba(188, 188, 188, 0.242) 30%,
      rgba(213, 213, 213, 0.2134) 39%,
      rgba(232, 232, 232, 0.1812) 48%,
      rgba(245, 245, 245, 0.1436) 58%,
      rgba(253, 253, 253, 0) 72%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    background-color: lighten(color(gray), 20%);
    content: ' ';
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .page_list_module__term__spring & {
    right: auto;
  }

  .page_list_module__term__fall & {
    left: auto;
  }
}
