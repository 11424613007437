@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.gdpr__container {
  @include krabbesholm-mono;
  @include font-size(default);

  border-top: .2rem solid color(red);

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @include breakpoints {
    padding: #{$web-module-padding*2};
    padding-top: 0;
    padding-bottom: $web-module-padding;
    width: 100%;
  }

  @include breakpoints(xs) {
    padding-left: $web-module-padding;
    padding-right: $web-module-padding;
  }

  background-color: color(white);
  padding-top: 1rem;

  z-index: 999;
}

.gdpr__container:not([hidden]) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
}

.gdpr__content {
  display: grid;
  grid-template-columns: 1fr auto;

  @include breakpoints {
    grid-column-gap: $web-module-padding;
    padding-top: $web-module-padding;
  }
}

.gdpr__consent {
  color: color(gray);
}

.gdpr__settings_label {
  display: grid;
  grid-template-areas: "input title"
                      "input description";
  grid-template-columns: min-content auto;
  grid-template-rows: auto auto;

  align-items: baseline;

  @include breakpoints {
    grid-column-gap: $web-module-padding / 2;
  }
}

.gdpr__settings_input {
  grid-area: input;
}

.gdpr__settings_title {
  grid-area: title;
}
.gdpr__settings_description {
  grid-area: description;
  color: color(gray);
}

.gdpr__settings {
  @include reset-list;

  @include breakpoints {
    padding-top: $web-module-padding;
  }
}

.gdpr__link {
  @include underline-for(default, color(gray)) text-decoration: none;
  color: color(gray);
}

.gdpr__buttons {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: start;

  @include breakpoints {
    grid-column-gap: $web-module-padding;
  }
}

.gdpr__button {
  @include krabbesholm-mono;
  @include font-size(default);
  @include reset-button;

  text-transform: uppercase;
  white-space: nowrap;
}

.gdpr__button[data-value="reject-all"] {
  color: color(blue);
}

.gdpr__button[data-value="customize"] {
  color: color(blue);
}

.gdpr__button[data-value="save-preferences"] {
  color: color(blue);
}

.gdpr__button[data-value="accept-all"] {
  color: color(blue);
}
