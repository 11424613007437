// Calculations for flexible grids:
//
//    fluid-col(width, 2); // => width: 20%
//    fluid-col(width, 2, 4); // => width: 50%
//    fluid-col(margin-left, 1); // => margin-left: 10%
//    fluid-col(- margin-left, 1); // => margin-left: -10%
//
//
// Allows for nesting:
//
//    fluid-col(width, 4); // => width: 40%;
//      fluid-col(width, 2 of 4); // => width: 50%;
//        fluid-col(width, 2 of 2 of 4); // => width: 100%;
//
//
// Accepts breakpoints definition:
//
//    fluid-col(width, 3, 6, ((query: '(max-width: 500px)', columns: 6)));
//
//    • this would result in the following markup:
//          @media (max-width: 500px) { width: 50%; }
//
//    • breakpoints are not applied to nested elements
//      (as they depend on their parent element which already responds to breakpoints)
//          fluid-col(width, 1 of 3, 6, ((query: '(max-width: 500px)', columns: 6)));
//      will therefore only result in:
//          width: 33.33333%
//
//    • in order to avoid passing the breakpoints to each @include statement,
//      the breakpoints can be defined globally in $fluid-col-breakpoints variable
//      for example as follows:
//          $fluid-col-breakpoints: (
//            s: (query: '(max-width: 600px)', columns: 6),
//            m: (query: '(min-width: 600px)', columns: 10),
//            l: (query: '(min-width: 900px)', columns: 14),
//            xl: (query: '(min-width: 1200px)', columns: 18)
//          );
//      following @include will then refer to them automatically:
//          fluid-col(width, 6); =>
//            @media (max-width: 600px) { width: 100%; }
//            @media (min-width: 600px) { width: 60%; }
//            @media (min-width: 900px) { width: 42.85714%; }
//            @media (min-width: 900px) { width: 33.33333%; }
//
//     • it is of course possible to again override the global breakpoins in specific cases
//          fluid-col(width, 3, 6, ((query: '(max-width: 500px)', columns: 6)));
//
//

@mixin fluid-col($prop, $args, $columns: 10, $breakpoints: null) {
  $multiplier: 1;

  $should-have-negative-value: (str-slice($prop, 0, 1) == '-');
  @if $should-have-negative-value {
    $multiplier: -1;
    $prop: str-slice($prop, 2, -1);
  }

  $is-parent-element: (length($args) == 1);
  @if $breakpoints and $is-parent-element {
    @each $name, $breakpoint in $breakpoints {
      $query: map-get($breakpoint, query);
      $breakpoint-columns: map-get($breakpoint, columns);
      @if str-length($query) > 0 {
        @media #{$query} {
          #{$prop}: $multiplier * fluid-col($args, $breakpoint-columns);
        }
      } @else {
        #{$prop}: $multiplier * fluid-col($args, $breakpoint-columns);
      }
    }
  } @else {
    #{$prop}: $multiplier * fluid-col($args, $columns);
  }
}
