@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);

@font-face {
  font-family: "Krabbesholm";
  font-style: normal;
  font-weight: normal;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_regular.eot?sha=ece345617206d180");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_regular.eot?sha=ece345617206d180") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_regular.woff2?sha=5f03f576cf4c06e5") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_regular.woff?sha=f90f389488d17643") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_regular.ttf?sha=371e195d229ac713")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fcmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_regular.svg?sha=e6beaf2824de1203") format('svg');
}

@font-face {
  font-family: "Krabbesholm";
  font-style: normal;
  font-weight: bold;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_bold.eot?sha=c035b0f608a8bc27");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_bold.eot?sha=c035b0f608a8bc27") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_bold.woff2?sha=3e6065ed7a1b10d0") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_bold.woff?sha=67943ef82386ab66") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_bold.ttf?sha=d6b7f04999b0acb1")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fYm9sZC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_bold.svg?sha=1cd9242eebc23f72") format('svg');
}

@font-face {
  font-family: "Krabbesholm Mono";
  font-style: normal;
  font-weight: normal;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_mono.eot?sha=77604453c49ec5a2");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_mono.eot?sha=77604453c49ec5a2") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_mono.woff2?sha=86b25f4f3463dcfe") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_mono.woff?sha=7fd6ccc88adbb0a2") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_mono.ttf?sha=0a1d3c6fb3d9e048")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9uby5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_mono.svg?sha=85f65ef74158806e") format('svg');
}

@font-face {
  font-family: "Krabbesholm Mono";
  font-style: italic;
  font-weight: normal;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_mono_slant.eot?sha=cff2b72f48d8cefc");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_mono_slant.eot?sha=cff2b72f48d8cefc") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_mono_slant.woff2?sha=ef9301ec0ea6b233") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_mono_slant.woff?sha=350826a0fe2acd2b") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_mono_slant.ttf?sha=8f52402f88858cd5")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fbW9ub19zbGFudC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_mono_slant.svg?sha=3d5cb4fe3bd6f875") format('svg');
}

@font-face {
  font-family: "Krabbesholm Fall";
  font-style: normal;
  font-weight: bold;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_fall.eot?sha=b35b4a0811c3f2d9");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_fall.eot?sha=b35b4a0811c3f2d9") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_fall.woff2?sha=db7a4380b1ce4d6b") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_fall.woff?sha=67bb595d77ee3fd6") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_fall.ttf?sha=9793785d23398de2")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fZmFsbC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_fall.svg?sha=e4acaf7c040d3a11") format('svg');
}

@font-face {
  font-family: "Krabbesholm Spring";
  font-style: normal;
  font-weight: bold;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/krabbesholm_spring.eot?sha=e41349f8b76c2592");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/krabbesholm_spring.eot?sha=e41349f8b76c2592") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYyIl1d/krabbesholm_spring.woff2?sha=d96898019775ab2b") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYiXV0/krabbesholm_spring.woff?sha=9e454a92eaf7ace8") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInR0ZiJdLFsicCIsInR0Zl9hdXRvaGludCJdXQ/krabbesholm_spring.ttf?sha=b695e3d1d877c679")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc3ByaW5nLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInN2ZyJdXQ/krabbesholm_spring.svg?sha=d058959b0e38b4cd") format('svg');
}

@font-face {
  font-family: "Krabbesholm Outline";
  font-style: normal;
  font-weight: bold;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_outline.eot?sha=0e343bd80c07d904");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/krabbesholm_outline.eot?sha=0e343bd80c07d904") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/krabbesholm_outline.woff2?sha=c8f57b87f9db1d2e") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/krabbesholm_outline.woff?sha=999db1df88e808ab") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/krabbesholm_outline.ttf?sha=37e999071e13c2ec")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fb3V0bGluZS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/krabbesholm_outline.svg?sha=832842d1d2589e65") format('svg');
}

@font-face {
  font-family: "Krabbesholm Serif";
  font-style: normal;
  font-weight: norma;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/krabbesholm_serif.eot?sha=20eb0d1787371954");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/krabbesholm_serif.eot?sha=20eb0d1787371954") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/krabbesholm_serif.woff2?sha=fec7e6d1fac422f7") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/krabbesholm_serif.woff?sha=3ab4cc83d26542b2") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/krabbesholm_serif.ttf?sha=ed7daf2e342ff47e")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWYub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/krabbesholm_serif.svg?sha=431941aa05a35a54") format('svg');
}

@font-face {
  font-family: "Krabbesholm Serif";
  font-style: italic;
  font-weight: norma;

  src: url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/krabbesholm_serif_italic.eot?sha=78d71e74a861ce23");
  src: local('☺'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/krabbesholm_serif_italic.eot?sha=78d71e74a861ce23") format('embedded-opentype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYyIl1d/krabbesholm_serif_italic.woff2?sha=b696e98a4e073e76") format('woff2'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYiXV0/krabbesholm_serif_italic.woff?sha=48393456f667b066") format('woff'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInR0ZiJdLFsicCIsInR0Zl9hdXRvaGludCJdXQ/krabbesholm_serif_italic.ttf?sha=00104ed80fe084e7")  format('truetype'),
       url("https://d20rtuzf6bjfu2.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMva3JhYmJlc2hvbG1fc2VyaWZfaXRhbGljLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInN2ZyJdXQ/krabbesholm_serif_italic.svg?sha=56a12fae6e055f90") format('svg');
}

