// @include semantic-date-time-tag(hide-day)

@mixin semantic-date-time-tag($type) {
  @if $type ==hide-year-if-same {
    .same_year {
      .date.from {

        .month.m+.sep,
        .year {
          display: none;
        }
      }
    }
  }

  @if $type ==hide-month-if-same {
    .same_year.same_month {
      .date.from {

        .day.d+.sep,
        .month.m,
        .month.m+.sep {
          display: none;
        }
      }
    }
  }

  @if $type ==hide-month {

    .month.m,
    .month.m+.sep {
      display: none;
    }
  }

  @if $type ==hide-year {
    .year.Y {
      display: none;
    }
  }

  @if $type ==hide-year-if-current {
    .current_year {
      .year.Y {
        display: none;
      }
    }
  }
}
