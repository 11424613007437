@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.upcoming_diary_entries__title {
  @include krabbesholm-mono;
  @include font-size(default);
  @include reset-margin;
  @include reset-padding;

  padding-bottom: .5em;
}

.upcoming_diary_entries__grouped_thumbnails {
  border-collapse: collapse;
  width: 100%;
}

.upcoming_diary_entries__tbody {}

.upcoming_diary_entries__column {
  border-top: 2px solid;

  padding-top: .5em;
  padding-bottom: .5em;
}

.upcoming_diary_entries__column[data-name="beginning-of-month"] {
  @include breakpoints {
    padding-right: $web-module-padding;
  }

  white-space: nowrap;
}

.upcoming_diary_entries__column[data-name="thumbnails"] {
  width: 100%;
}

.upcoming_diary_entries__tbody:last-child {
  .upcoming_diary_entries__column {
    border-bottom: 2px solid;
  }
}

.upcoming_diary_entries__beginning_of_month {
  @include semantic-date-time-tag(hide-year-if-current);
}

.upcoming_diary_entries__thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
}

.upcoming_diary_entries__thumbnail {}

.upcoming_diary_entries__thumbnail__dposted {
  @include semantic-date-time-tag(hide-year);
  @include semantic-date-time-tag(hide-month);
}

.upcoming_diary_entries__thumbnail__title:after {
  content: ".";
}

.upcoming_diary_entries__thumbnail__read_more {
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.upcoming_diary_entries__thumbnail__read_more:after {
  content: " …";
}
