@import 'partials';

$breakpoints: (xs: (columns: 1, exclusive_query: "all and (max-width: 479px)", query: "all"), s: (columns: 2, exclusive_query: "(min-width: 480px) and (max-width: 767px)", query: (min-width: 480px)), m: (columns: 4, exclusive_query: "(min-width: 768px) and (max-width: 1199px)", query: (min-width: 768px)), l: (columns: 4, exclusive_query: "(min-width: 1200px) and (max-width: 1919px)", query: (min-width: 1200px)), xl: (columns: 4, exclusive_query: "(min-width: 1920px)", query: "(min-width: 1920px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Info, announcements: Nyheder, application_form: Optagelse/Ansoegningsskema, application_form_consent: Optagelse/Ansoegningsskema/Consent, cookie_policy: Cookiepolitik, courses: Linjefag, diary: Hverdagen, evaluation_form: About/Evaluation-Form, everyday_life: Krabbesholm-Billeder, exhibitions: Udstilinger, how_to_apply: Optagelse, how_to_apply_general_information: Optagelse/General-Information, privacy_policy: Privacy-Policy, architecture: Faciliteter, projects: Projekter, publications: Publikationer, root: Index);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
body[data-path^="#{path(announcements)}"] {
  > .modulor_page {
    background-image: url("data:image/svg+xml," + line-svg(top_left, '#008d63')),
                      url("data:image/svg+xml," + line-svg(bottom_right, '#008d63'));
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: padding-box;
    background-position: 0% 0%, 100% 100%;
    background-clip: border-box;

    @include breakpoints(s xs) {
      margin: $web-module-padding;
    }

    @include breakpoints(xs) {
      padding-bottom: $web-module-padding;
    }
  }

  &.term__spring {
    > .modulor_page {
      background-image: url("data:image/svg+xml," + line-svg(top_left, '#008d63')),
                        url("data:image/svg+xml," + line-svg(bottom_right, '#008d63'));
      background-repeat: no-repeat;
      background-size: cover;
      background-origin: padding-box;
      background-position: 0% 0%, 100% 100%;
      background-clip: border-box;
    }
  }

  &.term__fall {
    > .modulor_page {
      background-image: url("data:image/svg+xml," + line-svg(top_left, '#9a4112')),
                        url("data:image/svg+xml," + line-svg(bottom_right, '#9a4112'));
      background-repeat: no-repeat;
      background-size: cover;
      background-origin: padding-box;
      background-position: 0% 0%, 100% 100%;
      background-clip: border-box;
    }
  }
}
