@charset "UTF-8";
.flickity-prev-next-button {
  font-family: 'Krabbesholm', sans-serif;
  font-weight: normal;
  font-weight: bold;
  background: transparent;
  border-radius: 0;
  height: 100%;
  min-width: 15%; }
  @media all and (max-width: 479px) {
    .flickity-prev-next-button {
      font-size: 2.231rem;
      line-height: 2.328rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .flickity-prev-next-button {
      font-size: 3.22rem;
      line-height: 3.36rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .flickity-prev-next-button {
      font-size: 3.68rem;
      line-height: 3.84rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .flickity-prev-next-button {
      font-size: 4.6rem;
      line-height: 4.8rem; } }
  @media (min-width: 1920px) {
    .flickity-prev-next-button {
      font-size: 4.6rem;
      line-height: 4.8rem; } }
  .flickity-prev-next-button svg {
    display: none; }
  .flickity-prev-next-button.next {
    padding: 0;
    right: 0;
    text-align: center; }
    .flickity-prev-next-button.next:before {
      content: '→';
      display: block;
      margin-bottom: -.25em; }
  .flickity-prev-next-button.previous {
    left: 0;
    padding: 0;
    text-align: center; }
    .flickity-prev-next-button.previous:before {
      content: '←';
      display: block;
      margin-bottom: -.25em; }

.flickity-prev-next-button:hover {
  background: transparent;
  color: red; }

.flickity-prev-next-button:disabled {
  display: none; }
