// ```html
// div class='wrapper_with_aspect_ratio'
//  div class='inside'
// ```
//
// ```css
// .wrapper_with_aspect_ratio {
//   @include aspect-ratio(1, 1, '> .inside')
// }
// ```

@mixin aspect-ratio($width, $height, $selector: '> .content') {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  #{$selector} {
    @include aspect-ratio-content;
  }
}

@mixin aspect-ratio-container {
  // + padding-bottom: XX%
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
}

@mixin aspect-ratio-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
