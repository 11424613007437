@charset "UTF-8";
.featured_diary_entry_module__link_to_title {
  color: inherit;
  text-decoration: none; }
  .featured_diary_entry_module__link_to_title:hover {
    color: red; }

.featured_diary_entry_module__thumbnail__first_paragraph {
  font-family: 'Krabbesholm Serif', serif;
  font-weight: normal;
  letter-spacing: .02em;
  margin: 0;
  padding: 0;
  text-align: left; }
  @media all and (max-width: 479px) {
    .featured_diary_entry_module__thumbnail__first_paragraph {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .featured_diary_entry_module__thumbnail__first_paragraph {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .featured_diary_entry_module__thumbnail__first_paragraph {
      font-size: 1.44rem;
      line-height: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .featured_diary_entry_module__thumbnail__first_paragraph {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  @media (min-width: 1920px) {
    .featured_diary_entry_module__thumbnail__first_paragraph {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
    font-family: 'Krabbesholm Mono', sans-serif;
    font-weight: normal;
    padding: 0;
    margin: 0;
    margin-bottom: 0; }
    @media all and (max-width: 479px) {
      .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
        font-size: 1.26rem;
        line-height: 1.68rem; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
        font-size: 1.26rem;
        line-height: 1.68rem; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
        font-size: 1.44rem;
        line-height: 1.92rem; } }
    @media (min-width: 1200px) and (max-width: 1919px) {
      .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
    @media (min-width: 1920px) {
      .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3, .featured_diary_entry_module__thumbnail__first_paragraph h4, .featured_diary_entry_module__thumbnail__first_paragraph h5 {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
    .featured_diary_entry_module__thumbnail__first_paragraph h1:not(:last-child), .featured_diary_entry_module__thumbnail__first_paragraph h2:not(:last-child), .featured_diary_entry_module__thumbnail__first_paragraph h3:not(:last-child), .featured_diary_entry_module__thumbnail__first_paragraph h4:not(:last-child), .featured_diary_entry_module__thumbnail__first_paragraph h5:not(:last-child) {
      margin-bottom: 1em; }
    .featured_diary_entry_module__thumbnail__first_paragraph h1:not(:first-child), .featured_diary_entry_module__thumbnail__first_paragraph h2:not(:first-child), .featured_diary_entry_module__thumbnail__first_paragraph h3:not(:first-child), .featured_diary_entry_module__thumbnail__first_paragraph h4:not(:first-child), .featured_diary_entry_module__thumbnail__first_paragraph h5:not(:first-child) {
      margin-top: 2em; }
  .featured_diary_entry_module__thumbnail__first_paragraph h1, .featured_diary_entry_module__thumbnail__first_paragraph h2, .featured_diary_entry_module__thumbnail__first_paragraph h3 {
    text-transform: uppercase; }
  .featured_diary_entry_module__thumbnail__first_paragraph ul, .featured_diary_entry_module__thumbnail__first_paragraph ol {
    margin: 0;
    padding: 0; }
    .featured_diary_entry_module__thumbnail__first_paragraph ul:not(:last-child), .featured_diary_entry_module__thumbnail__first_paragraph ol:not(:last-child) {
      margin-bottom: 1em; }
  .featured_diary_entry_module__thumbnail__first_paragraph ul {
    padding-left: 1em; }
  .featured_diary_entry_module__thumbnail__first_paragraph ol {
    padding-left: 2em; }
    .featured_diary_entry_module__thumbnail__first_paragraph ol > li:not(:last-child) {
      margin-bottom: 1em; }
  .featured_diary_entry_module__thumbnail__first_paragraph p {
    margin: 0;
    padding: 0; }
    .featured_diary_entry_module__thumbnail__first_paragraph p:not(:last-child) {
      margin-bottom: 1em; }
  .featured_diary_entry_module__thumbnail__first_paragraph a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.05em, black 0.05em, black 0.15em, rgba(0, 0, 0, 0) 0.15em); }
    .featured_diary_entry_module__thumbnail__first_paragraph a:hover {
      text-decoration: none;
      color: red;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.05em, red 0.05em, red 0.15em, rgba(0, 0, 0, 0) 0.15em); }
  .featured_diary_entry_module__thumbnail__first_paragraph strong,
  .featured_diary_entry_module__thumbnail__first_paragraph b {
    font-family: 'Krabbesholm', sans-serif;
    font-weight: normal;
    font-weight: bold; }
  .featured_diary_entry_module__thumbnail__first_paragraph blockquote {
    border-left: .3rem solid;
    margin: 0;
    padding: 0;
    padding-left: 2em;
    padding-left: calc(1em - .3rem); }
    .featured_diary_entry_module__thumbnail__first_paragraph blockquote:not(:last-child) {
      margin-bottom: 1em; }

.featured_diary_entry_module__thumbnail__title:after {
  content: "."; }

.featured_diary_entry_module__thumbnail__read_more a:after {
  content: " …"; }

.featured_diary_entry_module__thumbnail__gallery + .featured_diary_entry_module__thumbnail__first_paragraph {
  margin-top: 1em; }

.featured_diary_entry_module__thumbnail__dposted {
  font-family: 'Krabbesholm Mono', sans-serif;
  font-weight: normal;
  margin-top: 1em;
  text-align: left; }
  @media all and (max-width: 479px) {
    .featured_diary_entry_module__thumbnail__dposted {
      font-size: 0.72rem;
      line-height: 0.84rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .featured_diary_entry_module__thumbnail__dposted {
      font-size: 0.84rem;
      line-height: 0.98rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .featured_diary_entry_module__thumbnail__dposted {
      font-size: 0.96rem;
      line-height: 1.12rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .featured_diary_entry_module__thumbnail__dposted {
      font-size: 1.2rem;
      line-height: 1.4rem; } }
  @media (min-width: 1920px) {
    .featured_diary_entry_module__thumbnail__dposted {
      font-size: 1.2rem;
      line-height: 1.4rem; } }

.featured_diary_entry_module__upcoming_diary_entries {
  font-family: 'Krabbesholm Mono', sans-serif;
  font-weight: normal;
  margin-top: 1em;
  text-align: left; }
  @media all and (max-width: 479px) {
    .featured_diary_entry_module__upcoming_diary_entries {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .featured_diary_entry_module__upcoming_diary_entries {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .featured_diary_entry_module__upcoming_diary_entries {
      font-size: 1.44rem;
      line-height: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .featured_diary_entry_module__upcoming_diary_entries {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  @media (min-width: 1920px) {
    .featured_diary_entry_module__upcoming_diary_entries {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  .term__spring .featured_diary_entry_module__upcoming_diary_entries {
    color: #008d63; }
  .term__fall .featured_diary_entry_module__upcoming_diary_entries {
    color: #9a4012; }
