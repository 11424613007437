@mixin svg-line-bg($dir: horizontal, $color: color(black), $width: 2.5) {
  background-image: svg-uri(svg-line($dir, $color, $width));
  @if $dir == horizontal { background-size: #{"100%" $width/10+"rem"}; }
  @if $dir == vertical { background-size: #{$width/10+"rem" "100%"}; }

  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: 0% 0%, 100% 100%;
  background-clip: border-box;
}
