@charset "UTF-8";
.upcoming_diary_entries__title {
  font-family: 'Krabbesholm Mono', sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
  padding-bottom: .5em; }
  @media all and (max-width: 479px) {
    .upcoming_diary_entries__title {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .upcoming_diary_entries__title {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .upcoming_diary_entries__title {
      font-size: 1.44rem;
      line-height: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .upcoming_diary_entries__title {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  @media (min-width: 1920px) {
    .upcoming_diary_entries__title {
      font-size: 1.8rem;
      line-height: 2.4rem; } }

.upcoming_diary_entries__grouped_thumbnails {
  border-collapse: collapse;
  width: 100%; }

.upcoming_diary_entries__column {
  border-top: 2px solid;
  padding-top: .5em;
  padding-bottom: .5em; }

.upcoming_diary_entries__column[data-name="beginning-of-month"] {
  white-space: nowrap; }
  @media all and (max-width: 479px) {
    .upcoming_diary_entries__column[data-name="beginning-of-month"] {
      padding-right: 1.2rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .upcoming_diary_entries__column[data-name="beginning-of-month"] {
      padding-right: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .upcoming_diary_entries__column[data-name="beginning-of-month"] {
      padding-right: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .upcoming_diary_entries__column[data-name="beginning-of-month"] {
      padding-right: 2.4rem; } }
  @media (min-width: 1920px) {
    .upcoming_diary_entries__column[data-name="beginning-of-month"] {
      padding-right: 2.4rem; } }

.upcoming_diary_entries__column[data-name="thumbnails"] {
  width: 100%; }

.upcoming_diary_entries__tbody:last-child .upcoming_diary_entries__column {
  border-bottom: 2px solid; }

.upcoming_diary_entries__beginning_of_month .current_year .year.Y {
  display: none; }

.upcoming_diary_entries__thumbnails {
  list-style: none;
  margin: 0;
  padding: 0; }

.upcoming_diary_entries__thumbnail__dposted .year.Y {
  display: none; }

.upcoming_diary_entries__thumbnail__dposted .month.m,
.upcoming_diary_entries__thumbnail__dposted .month.m + .sep {
  display: none; }

.upcoming_diary_entries__thumbnail__title:after {
  content: "."; }

.upcoming_diary_entries__thumbnail__read_more a {
  color: inherit;
  text-decoration: underline; }

.upcoming_diary_entries__thumbnail__read_more:after {
  content: " …"; }
