@mixin krabbesholm {
  font-family: 'Krabbesholm', sans-serif;
  font-weight: normal;
}

@mixin krabbesholm-bold {
  @include krabbesholm;
  font-weight: bold;
}

@mixin krabbesholm-mono {
  font-family: 'Krabbesholm Mono', sans-serif;
  font-weight: normal;
}

// ---------------------------------------------------------------------

@mixin krabbesholm-fall {
  font-family: 'Krabbesholm Fall', sans-serif;
  font-weight: bold;
}

@mixin krabbesholm-spring {
  font-family: 'Krabbesholm Spring', sans-serif;
  font-weight: bold;
}

// ---------------------------------------------------------------------

@mixin krabbesholm-outline {
  font-family: 'Krabbesholm Outline', sans-serif;
  font-weight: bold;
}

// ---------------------------------------------------------------------

@mixin krabbesholm-serif {
  font-family: 'Krabbesholm Serif', serif;
  font-weight: normal;
  letter-spacing: .02em;
}
