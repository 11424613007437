@mixin link($type, $size: default) {
  text-decoration: none;

  @if $type == black-with-red-hover {
    color: color(black);
    &:hover {
      color: color(red);
    }
  }

  @if $type == gray-with-black-hover {
    color: color(gray);
    &:hover {
      color: color(black);
    }
  }

  @if $type == gray-with-red-hover {
    color: color(gray);
    &:hover {
      color: color(red);
    }
  }

  @if $type == gray-with-blue-hover {
    color: color(gray);
    &:hover {
      color: color(blue);
    }
  }

  @if $type == gray-with-spring-hover {
    color: color(gray);
    &:hover {
      color: color(spring);
    }
  }

  @if $type == gray-with-fall-hover {
    color: color(gray);
    &:hover {
      color: color(fall);
    }
  }

  @if $type == white-with-black-hover {
    color: color(white);
    &:hover {
      color: color(black);
    }
  }

  @if $type == blue-with-black-hover {
    color: color(blue);
    &:hover {
      color: color(black);
    }
  }

  @if $type == spring-with-black-hover {
    color: color(spring);
    &:hover {
      color: color(black);
    }
  }

  @if $type == fall-with-black-hover {
    color: color(fall);
    &:hover {
      color: color(black);
    }
  }

  @if $type == red {
    color: color(red);
    &:hover {
      color: color(red);
    }
  }

  @if $type == blue-with-underline {
    color: color(blue);
    @include underline-for($size, color(blue));
  }

  @if $type == red-with-underline {
    color: color(red);
    @include underline-for($size, color(red));
  }

  @if $type == black-with-underline {
    color: color(black);
    @include underline-for($size, color(black));
  }

  @if $type == spring-with-underline {
    color: color(spring);
    @include underline-for($size, color(spring));
  }

  @if $type == fall-with-underline {
    color: color(fall);
    @include underline-for($size, color(fall));
  }
}
