[data-path^='Info/'] .modulor_web_modules {
  display: block;
  flex-direction: row;
  flex-wrap: wrap; }

[data-path^='Info/'].term__spring .modulor_page__is_current > .modulor_web_modules, [data-path^='Info/'].term__school_break .modulor_page__is_current > .modulor_web_modules {
  flex-direction: row; }
  [data-path^='Info/'].term__spring .modulor_page__is_current > .modulor_web_modules > .text_module:not(.modulor_web_module__is_edit_mode), [data-path^='Info/'].term__school_break .modulor_page__is_current > .modulor_web_modules > .text_module:not(.modulor_web_module__is_edit_mode) {
    float: left; }
  [data-path^='Info/'].term__spring .modulor_page__is_current > .modulor_web_modules > .image_module:not(.modulor_web_module__is_edit_mode), [data-path^='Info/'].term__school_break .modulor_page__is_current > .modulor_web_modules > .image_module:not(.modulor_web_module__is_edit_mode) {
    display: inline-block;
    vertical-align: middle; }
  [data-path^='Info/'].term__spring .modulor_page__is_current > .modulor_web_modules > .map_module:not(.modulor_web_module__is_edit_mode),
  [data-path^='Info/'].term__spring .modulor_page__is_current > .modulor_web_modules > .file_module:not(.modulor_web_module__is_edit_mode), [data-path^='Info/'].term__school_break .modulor_page__is_current > .modulor_web_modules > .map_module:not(.modulor_web_module__is_edit_mode),
  [data-path^='Info/'].term__school_break .modulor_page__is_current > .modulor_web_modules > .file_module:not(.modulor_web_module__is_edit_mode) {
    clear: both; }

[data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules {
  flex-direction: row-reverse;
  align-items: flex-start; }
  [data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules > .text_module:not(.modulor_web_module__is_edit_mode),
  [data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules > .form_module:not(.modulor_web_module__is_edit_mode) {
    float: right; }
  [data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules > .image_module:not(.modulor_web_module__is_edit_mode) {
    display: inline-block;
    vertical-align: middle; }
  [data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules > .map_module:not(.modulor_web_module__is_edit_mode),
  [data-path^='Info/'].term__fall .modulor_page__is_current > .modulor_web_modules > .file_module:not(.modulor_web_module__is_edit_mode) {
    clear: both; }
