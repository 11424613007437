@mixin website_is_in_spring_term {
  body.term__spring & { @content; }
}

@mixin website_is_in_school_break {
  body.term__school_break & { @content; }
}

@mixin website_is_in_fall_term {
  body.term__fall & { @content; }
}
