@mixin parent-page {
  @include krabbesholm-bold;
  @include font-size(large);
  @include reset-margin;
  @include reset-padding;

  a { @include link(black-with-red-hover); }

  @include website_is_in_spring_term {
    text-align: left;
  }

  @include website_is_in_school_break {
    text-align: left;
  }

  @include website_is_in_fall_term {
    text-align: right;
  }
}
