@charset "UTF-8";
.diary_entry_page__thumbnail {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media all and (max-width: 479px) {
    .diary_entry_page__thumbnail {
      gap: 1.2rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .diary_entry_page__thumbnail {
      gap: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .diary_entry_page__thumbnail {
      gap: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .diary_entry_page__thumbnail {
      gap: 2.4rem; } }
  @media (min-width: 1920px) {
    .diary_entry_page__thumbnail {
      gap: 2.4rem; } }

.diary_entry_page__thumbnail[data-term="fall"] {
  grid-template-areas: "slides description"; }

.diary_entry_page__thumbnail[data-term="spring"] {
  grid-template-areas: "description slides"; }

.diary_entry_page__thumbnail__description {
  grid-area: description; }

.diary_entry_page__thumbnail__slides {
  grid-area: slides; }

.diary_entry_page__thumbnail__dots {
  display: flex;
  justify-content: center; }

.diary_entry_page__thumbnail__title:after {
  content: "."; }

.diary_entry_page__thumbnail__first_paragraph {
  font-family: 'Krabbesholm Serif', serif;
  font-weight: normal;
  letter-spacing: .02em;
  margin: 0;
  padding: 0;
  text-align: left; }
  @media all and (max-width: 479px) {
    .diary_entry_page__thumbnail__first_paragraph {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .diary_entry_page__thumbnail__first_paragraph {
      font-size: 1.26rem;
      line-height: 1.68rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .diary_entry_page__thumbnail__first_paragraph {
      font-size: 1.44rem;
      line-height: 1.92rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .diary_entry_page__thumbnail__first_paragraph {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  @media (min-width: 1920px) {
    .diary_entry_page__thumbnail__first_paragraph {
      font-size: 1.8rem;
      line-height: 2.4rem; } }
  .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
    font-family: 'Krabbesholm Mono', sans-serif;
    font-weight: normal;
    padding: 0;
    margin: 0;
    margin-bottom: 0; }
    @media all and (max-width: 479px) {
      .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
        font-size: 1.26rem;
        line-height: 1.68rem; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
        font-size: 1.26rem;
        line-height: 1.68rem; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
        font-size: 1.44rem;
        line-height: 1.92rem; } }
    @media (min-width: 1200px) and (max-width: 1919px) {
      .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
    @media (min-width: 1920px) {
      .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3, .diary_entry_page__thumbnail__first_paragraph h4, .diary_entry_page__thumbnail__first_paragraph h5 {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
    .diary_entry_page__thumbnail__first_paragraph h1:not(:last-child), .diary_entry_page__thumbnail__first_paragraph h2:not(:last-child), .diary_entry_page__thumbnail__first_paragraph h3:not(:last-child), .diary_entry_page__thumbnail__first_paragraph h4:not(:last-child), .diary_entry_page__thumbnail__first_paragraph h5:not(:last-child) {
      margin-bottom: 1em; }
    .diary_entry_page__thumbnail__first_paragraph h1:not(:first-child), .diary_entry_page__thumbnail__first_paragraph h2:not(:first-child), .diary_entry_page__thumbnail__first_paragraph h3:not(:first-child), .diary_entry_page__thumbnail__first_paragraph h4:not(:first-child), .diary_entry_page__thumbnail__first_paragraph h5:not(:first-child) {
      margin-top: 2em; }
  .diary_entry_page__thumbnail__first_paragraph h1, .diary_entry_page__thumbnail__first_paragraph h2, .diary_entry_page__thumbnail__first_paragraph h3 {
    text-transform: uppercase; }
  .diary_entry_page__thumbnail__first_paragraph ul, .diary_entry_page__thumbnail__first_paragraph ol {
    margin: 0;
    padding: 0; }
    .diary_entry_page__thumbnail__first_paragraph ul:not(:last-child), .diary_entry_page__thumbnail__first_paragraph ol:not(:last-child) {
      margin-bottom: 1em; }
  .diary_entry_page__thumbnail__first_paragraph ul {
    padding-left: 1em; }
  .diary_entry_page__thumbnail__first_paragraph ol {
    padding-left: 2em; }
    .diary_entry_page__thumbnail__first_paragraph ol > li:not(:last-child) {
      margin-bottom: 1em; }
  .diary_entry_page__thumbnail__first_paragraph p {
    margin: 0;
    padding: 0; }
    .diary_entry_page__thumbnail__first_paragraph p:not(:last-child) {
      margin-bottom: 1em; }
  .diary_entry_page__thumbnail__first_paragraph a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.05em, black 0.05em, black 0.15em, rgba(0, 0, 0, 0) 0.15em); }
    .diary_entry_page__thumbnail__first_paragraph a:hover {
      text-decoration: none;
      color: red;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.05em, red 0.05em, red 0.15em, rgba(0, 0, 0, 0) 0.15em); }
  .diary_entry_page__thumbnail__first_paragraph strong,
  .diary_entry_page__thumbnail__first_paragraph b {
    font-family: 'Krabbesholm', sans-serif;
    font-weight: normal;
    font-weight: bold; }
  .diary_entry_page__thumbnail__first_paragraph blockquote {
    border-left: .3rem solid;
    margin: 0;
    padding: 0;
    padding-left: 2em;
    padding-left: calc(1em - .3rem); }
    .diary_entry_page__thumbnail__first_paragraph blockquote:not(:last-child) {
      margin-bottom: 1em; }

.diary_entry_page__thumbnail__read_more a:after {
  content: " …"; }

.diary_entry_page__thumbnail__dposted {
  font-family: 'Krabbesholm Mono', sans-serif;
  font-weight: normal;
  margin-top: 1em;
  text-align: left; }
  @media all and (max-width: 479px) {
    .diary_entry_page__thumbnail__dposted {
      font-size: 0.72rem;
      line-height: 0.84rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .diary_entry_page__thumbnail__dposted {
      font-size: 0.84rem;
      line-height: 0.98rem; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .diary_entry_page__thumbnail__dposted {
      font-size: 0.96rem;
      line-height: 1.12rem; } }
  @media (min-width: 1200px) and (max-width: 1919px) {
    .diary_entry_page__thumbnail__dposted {
      font-size: 1.2rem;
      line-height: 1.4rem; } }
  @media (min-width: 1920px) {
    .diary_entry_page__thumbnail__dposted {
      font-size: 1.2rem;
      line-height: 1.4rem; } }
