@mixin basic-text-formatting {
  h1, h2, h3, h4, h5 {
    @include krabbesholm-mono;
    @include font-size(default);
    @include reset-header;
    margin-bottom: 0;
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  h1, h2, h3 {
    text-transform: uppercase;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  ul {
    padding-left: 1em;
  }

  ol {
    padding-left: 2em;
    > li:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  p {
    @include reset-p;
  }
}
